"use client"

import type { TrackingModule } from "./types"

import { createContext, useContext } from "react"

const TrackingLibContext = createContext<TrackingModule | null>(null)

const useTracking = (): TrackingModule | null => useContext(TrackingLibContext)

export { TrackingLibContext, useTracking }
