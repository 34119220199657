import type { TrackingModule } from "./types"
import type { I18nLocale } from "@/modules/i18n/types"
import type { UnleashContext } from "@/modules/unleash/types"
import type { RudderstackConfig } from "@jobteaser/js-tracking"

const CONFIG_TRACKING_JT = {
  batchConfigUrl: process.env.NEXT_PUBLIC_TRACKING_BATCH_CONFIG_URL,
  debug: process.env.NODE_ENV === "development",

  sdk: {
    didomi: {
      enabled: true,
      noticeId: process.env.NEXT_PUBLIC_TRACKING_DIDOMI_NOTICE_ID || "",
    },
    rudderstack: {
      dataPlaneURL: process.env.NEXT_PUBLIC_TRACKING_RUDDERSTACK_PLANE_URL || "",
      enabled: true,
      jsKey: process.env.NEXT_PUBLIC_TRACKING_RUDDERSTACK_JS_KEY || "",
    },
  },
  // If `trackingEnabled` if false, no events are sent to Rudderstack
  trackingEnabled: process.env.NEXT_PUBLIC_TRACKING_ENABLED,
}

export function getTrackingConfig(locale: I18nLocale, unleashContext: UnleashContext): RudderstackConfig {
  return {
    ...CONFIG_TRACKING_JT,
    context: {
      is_not_a_bot: unleashContext.is_not_a_bot === "true",
      user_country: unleashContext.user_country,
    },
    locale,
    startRudderstackOnInit: false,
  }
}

export const initTracking = async (
  locale: I18nLocale,
  unleashContext: UnleashContext,
  callback: (arg: TrackingModule) => void
): Promise<void> => {
  /*
   * Using dynamic import ensures that code that relies on the window object
   * is only loaded client-side, which prevents the “ReferenceError: window is not defined” error
   * and improves website performance.
   */
  const { TrackingJT } = await (process.env.NEXT_PUBLIC_MOCK_ENV_CONFIG === "true"
    ? import("./fake/jsTracking")
    : import("@jobteaser/js-tracking"))
  const trackingJT = TrackingJT(getTrackingConfig(locale, unleashContext)) as TrackingModule

  if (callback) {
    callback(trackingJT)
  }
}
