"use client"

import type { FunctionComponent } from "react"

import { useRouter } from "next/navigation"

import { IconButton } from "@jobteaser/spark/components/IconButton"
import { Cross } from "@jobteaser/spark/components/Icons/Cross"

import { setCookie } from "@/modules/client/cookies/setCookie"
import { NOTIFICATION_BANNER_COOKIE_NAME } from "@/modules/notificationBanner/constants"

type CloseBannerButtonProps = {
  ariaLabel: string
  dismissBannerId: string
}

export const CloseBannerButton: FunctionComponent<CloseBannerButtonProps> = ({ ariaLabel, dismissBannerId }) => {
  const router = useRouter()

  const handleClose = (): void => {
    setCookie({ name: NOTIFICATION_BANNER_COOKIE_NAME, value: dismissBannerId })
    router.refresh()
  }

  return (
    <IconButton
      aria-label={ariaLabel}
      data-testid="close-banner"
      icon={Cross}
      onBackground="dark"
      onClick={handleClose}
      size="medium"
      title={ariaLabel}
      variant="transparent"
    />
  )
}
