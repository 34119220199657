/* eslint-disable global-require */

// //////////////////////////////////////////////////////////////////////////
// THIS FILE SHOULD BE KEPT IN JAVASCRIPT BECAUSE USED IN NEXT.CONFIG.JS FILE
// //////////////////////////////////////////////////////////////////////////

/**
 * Return the correct url to be used to get static assets
 * If running in local env, your static assets are probably served from your webserver.
 * If running from feature env, staging or production envs, these assets are served through CDN
 * @returns {string} stringified URL to use as hostname + first section of the path
 */
const getAssetsUrl = () => {
  const env = process.env.NEXT_PUBLIC_ENV || "local"

  if (env === "local") {
    return ""
  }

  const commit = `${process.env.NEXT_PUBLIC_CIRCLE_SHA1 || ""}`
  const cdn = `${process.env.NEXT_PUBLIC_STATIC_ASSETS_JOBTEASERCDN_URL || ""}`
  let domain

  switch (env) {
    case "feature": {
      if (!cdn || !commit) {
        throw new Error("for feature env, need cdn and commit values")
      }
      domain = `${cdn}/${commit}`
      break
    }
    case "staging": {
      if (!cdn) {
        throw new Error("for staging, need a cdn value")
      }
      domain = `${cdn}/staging`
      break
    }
    case "production": {
      if (!cdn) {
        throw new Error("for production, need a cdn value")
      }
      domain = `${cdn}/production`
      break
    }
    default:
      throw Error(`Env "${env}" is not supported.`)
  }

  return domain
}

/**
 * Return the correct base url
 * If running in local env, your static assets are probably served from your webserver.
 * If running from feature env, staging or production envs, these assets are served through CDN
 * @returns {string} stringified URL
 */
const getAssetsBaseUrl = () => {
  const env = process.env.NEXT_PUBLIC_ENV || "local"

  let domain
  switch (env) {
    case "local": {
      domain = ""
      break
    }
    default: {
      const cdn = `${process.env.NEXT_PUBLIC_STATIC_ASSETS_JOBTEASERCDN_URL || ""}`
      if (!cdn) {
        throw new Error("need a cdn value")
      }
      domain = cdn
    }
  }

  return domain
}

module.exports = {
  getAssetsBaseUrl,
  getAssetsUrl,
}
